import { EditorState } from 'draft-js';

/**
 * @typedef {import('draft-js').DraftModel.ImmutableData.EditorState} EditorState
 * @typedef {import('draft-js').DraftModel.ImmutableData.ContentState} ContentState
 * @typedef {import('draft-js').DraftModel.ImmutableData.SelectionState} SelectionState
 */

/**
 * @param {object} params
 * @param {ContentState} params.contentState
 * @param {ContentState} params.prevContentState
 * @param {SelectionState} params.prevSelection
 * @param {EditorState} params.editorState
 * @returns {EditorState}
 */
export default function getEditorStateWithForcedSelection({
  contentState,
  prevContentState,
  prevSelection,
  editorState,
}) {
  const selection = editorState.getSelection();
  const block = contentState.getBlockForKey(selection.getStartKey());
  const prevBlock = prevContentState.getBlockForKey(prevSelection.getStartKey());

  if (prevBlock && block.getKey() !== prevBlock.getKey()) {
    // force selection so the editor will trigger blockRendererFn
    return EditorState.forceSelection(editorState, selection);
  }

  return editorState;
}
