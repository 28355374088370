import deleteEditorComment from '@/editor/utils/deleteEditorComment';
import addCommentToEditorState from '@/editor/utils/addCommentToEditorState';
import updateEditorComment from '@/editor/utils/updateEditorComment';

/**
 * Add missing, remove deleted, and update stale comments in the editor state.
 *
 * @param {object} editorState
 * @param {Array} editorComments
 * @param {Array} comments
 * @returns {object}
 */
export default function syncEditorComments(editorState, editorComments, comments) {
  let newEditorState = editorState;
  const activeComments = comments.filter((c) => !c.resolved && !c.deleted_at);

  // Remove from editor if the comment is deleted
  editorComments.forEach((editorComment) => {
    if (!activeComments.some((c) => c.editor_key === editorComment.key)) {
      // Comment is deleted but is still inside the editorState, we need to remove it from the editor state
      newEditorState = deleteEditorComment(editorComment, newEditorState);
    }
  });

  // Add and update comments
  activeComments.forEach((comment) => {
    if (!comment.parent_id) {
      const editorComment = editorComments.find(
        (_editorComment) => comment.editor_key === _editorComment.key,
      );
      if (!editorComment) {
        if (comment.editor_ranges) {
          // Add editor comment
          newEditorState = addCommentToEditorState(comment, newEditorState);
        }
      } else {
        // Update editor comment
        newEditorState = updateEditorComment(editorComment, newEditorState, comment.resolved);
      }
    }
  });

  return newEditorState;
}
