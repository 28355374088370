import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import TextEditor, {
  propTypes as textEditorPropTypes,
  defaultProps as textEditorDefaultProps,
} from '@/storychief/components/editors/TextEditor';
import ModelContext from '@/storychief/context/ModelContext';
import useAvailableUsersForContent from '@/collaborators/hooks/useAvailableUsersForContent';
import useModelSignKey from '@/storychief/hooks/useModelSignKey';
import usePreview from '@/storychief/hooks/usePreview';
import { ROLE_CLIENT, ROLE_GUEST } from '@/users/constants/Constants';

const propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      firstname: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      lastname: PropTypes.string,
      profile_picture: PropTypes.string,
      role: PropTypes.number,
    }),
  ).isRequired,
  ...textEditorPropTypes,
};

const defaultProps = {
  ...textEditorDefaultProps,
};

const QUERIES = {
  Story: gql`
    query MentionSuggestionsStory($id: ID!) {
      item: story(id: $id) {
        __typename
        id
        is_private
      }
    }
  `,
  Postset: gql`
    query MentionSuggestionsPostset($id: ID!) {
      item: postset(id: $id) {
        __typename
        id
        is_private
      }
    }
  `,
  WebsiteContent: gql`
    query MentionSuggestionsWebsiteContent($id: ID!) {
      item: websiteContent(id: $id) {
        __typename
        id
        is_private
      }
    }
  `,
  VideoProject: gql`
    query MentionSuggestionsVideoProject($id: ID!) {
      item: videoProject(id: $id) {
        __typename
        id
        is_private
      }
    }
  `,
  Newsletter: gql`
    query MentionSuggestionsNewsletter($id: ID!) {
      item: newsletter(id: $id) {
        __typename
        id
        is_private
      }
    }
  `,
  Ebook: gql`
    query MentionSuggestionsEbook($id: ID!) {
      item: ebook(id: $id) {
        __typename
        id
        is_private
      }
    }
  `,
  Webinar: gql`
    query MentionSuggestionsWebinar($id: ID!) {
      item: webinar(id: $id) {
        __typename
        id
        is_private
      }
    }
  `,
  Podcast: gql`
    query MentionSuggestionsPodcast($id: ID!) {
      item: podcast(id: $id) {
        __typename
        id
        is_private
      }
    }
  `,
  Task: gql`
    query MentionSuggestionsTask($id: ID!) {
      item: task(id: $id) {
        __typename
        id
      }
    }
  `,
};

const PUBLIC_QUERIES = {
  Story: gql`
    query MentionSuggestionsPublicStory($id: ID!) {
      item: publicStory(id: $id) {
        __typename
        id
        is_private
      }
    }
  `,
  Postset: gql`
    query MentionSuggestionsPublicPostset($id: ID!) {
      item: publicPostset(id: $id) {
        __typename
        id
        is_private
      }
    }
  `,
  WebsiteContent: gql`
    query MentionSuggestionsPublicWebsiteContent($id: ID!) {
      item: publicWebsiteContent(id: $id) {
        __typename
        id
        is_private
      }
    }
  `,
  VideoProject: gql`
    query MentionSuggestionsPublicVideoProject($id: ID!) {
      item: publicVideoProject(id: $id) {
        __typename
        id
        is_private
      }
    }
  `,
  Newsletter: gql`
    query MentionSuggestionsPublicNewsletter($id: ID!) {
      item: publicNewsletter(id: $id) {
        __typename
        id
        is_private
      }
    }
  `,
  Ebook: gql`
    query MentionSuggestionsPublicEbook($id: ID!) {
      item: publicEbook(id: $id) {
        __typename
        id
        is_private
      }
    }
  `,
  Webinar: gql`
    query MentionSuggestionsPublicWebinar($id: ID!) {
      item: publicWebinar(id: $id) {
        __typename
        id
        is_private
      }
    }
  `,
  Podcast: gql`
    query MentionSuggestionsPublicPodcast($id: ID!) {
      item: publicPodcast(id: $id) {
        __typename
        id
        is_private
      }
    }
  `,
};

function TextEditorWithUserMentions(props) {
  // Hooks
  const [mentionSearchResults, setMentionSearchResults] = useState([]);
  const { preview } = usePreview();
  const availableUsersForContent = useAvailableUsersForContent();
  const { modelId, modelType } = useContext(ModelContext);
  const modelSignKey = useModelSignKey({ id: modelId, typename: modelType });
  const modelData = useQuery(getQuery(), {
    variables: { id: modelId },
    context: modelSignKey ? { headers: modelSignKey } : {},
    skip: !modelType || !modelId,
  });

  // Variables
  const isPrivate = modelData.data?.item?.is_private || false;

  // Functions
  function getQuery() {
    return modelSignKey ? PUBLIC_QUERIES[modelType] : QUERIES[modelType];
  }

  function handleMentionSearch(searchText) {
    if (mentionSearchResults.find((result) => result.key === searchText)) return;

    if (!availableUsersForContent.length) {
      setMentionSearchResults([]);
      return;
    }

    const usersFiltered = props.users
      .filter((user) => {
        if (preview && [ROLE_GUEST, ROLE_CLIENT].includes(user.role)) {
          // Hide users who aren't available to anonymous users
          return availableUsersForContent.some((u) => `${u.id}` === `${user.id}`);
        }

        return true;
      })
      .filter((user) =>
        `${user.firstname} ${user.lastname}`.toLowerCase().includes(searchText.toLowerCase()),
      );
    const searchResult = usersFiltered.map((user) => ({
      id: user.id,
      userId: user.id,
      name: `${user.firstname} ${user.lastname || ''}`,
      picture: user.profile_picture,
      // IDs may not be the same type (mixture of REST API data and GraphQL data).
      disabled: !availableUsersForContent.some((u) => `${u.id}` === `${user.id}`),
    }));
    setMentionSearchResults(searchResult);
  }

  // Render
  if (props.users && props.users.length) {
    return (
      <TextEditor
        {...props}
        mentionSearchResults={mentionSearchResults}
        mentionDisabledTooltip={
          isPrivate
            ? 'This user is not a collaborator in this private campaign'
            : 'Guest writers must be added as collaborators to be mentioned'
        }
        onMentionSearch={handleMentionSearch}
        mentionType="SELECT"
      />
    );
  }
  return <TextEditor {...props} />;
}

TextEditorWithUserMentions.propTypes = propTypes;
TextEditorWithUserMentions.defaultProps = defaultProps;

export default TextEditorWithUserMentions;
