import { animateScroll } from 'react-scroll';

/**
 * @param {string} editorKey
 */
export default function scrollSidebarToSelectedComment({ key, id }) {
  function getSelector() {
    return key ? `div[data-comment-key="${key}"]` : `div[data-comment-id="${id}"]`;
  }

  setTimeout(() => {
    const sidebarContentId = 'sidebar-content-scroll-wrapper';
    const sidebarContainer = document.getElementById(sidebarContentId);

    if (sidebarContainer) {
      const recSidebar = sidebarContainer.getBoundingClientRect();
      const selectedCommentDomNode = document.querySelector(getSelector());

      if (selectedCommentDomNode) {
        animateScroll.scrollTo(selectedCommentDomNode.offsetTop - recSidebar.top, {
          containerId: sidebarContentId,
        });
      }
    }
  }, 1);
}
