import { EditorState, Modifier, SelectionState } from 'draft-js';

/**
 * @param {object} comment
 * @param {object} editorState
 * @returns {object}
 */
export default function addCommentToEditorState(comment, editorState) {
  let newContentState = editorState.getCurrentContent();

  if (comment.editor_ranges) {
    const editorRanges = JSON.parse(comment.editor_ranges);
    editorRanges.forEach((range) => {
      const currentBlock = newContentState.getBlockForKey(range.blockKey);
      if (currentBlock && currentBlock.getText().length >= range.end) {
        const newSelectionState = new SelectionState({
          anchorOffset: range.start,
          anchorKey: range.blockKey,
          focusOffset: range.end,
          focusKey: range.blockKey,
        });
        // add comment style
        newContentState = Modifier.applyInlineStyle(
          newContentState,
          newSelectionState,
          comment.editor_key,
        );
      }
    });
  }

  return EditorState.set(editorState, { currentContent: newContentState });
}
