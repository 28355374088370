export default function findCurrentEditorComment({
  editorState,
  currentEditorCommentKeys,
  editorComments,
}) {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const block = contentState.getBlockForKey(selection.getStartKey());

  // Find the nearest editorComment next to the current selection
  // First we get the ranges of the current editorComments
  const currentEditorCommentKeysWithRange = editorComments
    .filter((editorComment) =>
      currentEditorCommentKeys.find((editorCommentKey) => editorCommentKey === editorComment.key),
    )
    .map((editorComment) => ({
      key: editorComment.key,
      ...editorComment.ranges.find((range) => range.blockKey === block.getKey()),
    }));

  // Secondly we search the nearest editorComment to the current selection
  const currentEditorComment = currentEditorCommentKeysWithRange.reduce((prev, curr) =>
    Math.abs(curr.start - selection.getFocusOffset()) <
    Math.abs(prev.start - selection.getFocusOffset())
      ? curr
      : prev,
  );

  return currentEditorComment;
}
