import { COMMENTS_FILTER_OPTIONS } from '@/comments/constants/Constants';
import StoryChief from '@/storychief';

function isCurrentUserMentioned(comment) {
  if (comment.content) {
    const entities = Object.values(JSON.parse(comment.content).entityMap);
    return entities.some(
      (e) => e.type === 'mention' && `${e.data.userId}` === `${StoryChief.user.id}`, // IDs may not be the same type (mixture of REST API data and GraphQL data).
    );
  }

  return false;
}

function isCurrentUserAuthor(comment) {
  // Logged in user
  if (comment.user) {
    return comment.user.id === `${StoryChief.user.id}`;
  }

  // Guest user
  return comment.user_email === StoryChief.user.email;
}

function isCurrentUserMentionedOrCommentedInThread(comment) {
  return comment.replies.some((r) => isCurrentUserAuthor(r) || isCurrentUserMentioned(r));
}

/**
 * @param {Array} comments
 * @param {COMMENTS_FILTER_OPTIONS} filter
 * @returns {Array}
 */
export default function getFilteredComments(comments, filter) {
  switch (filter) {
    case COMMENTS_FILTER_OPTIONS.open:
      return comments.filter((c) => !c.resolved && !c.deleted_at);
    case COMMENTS_FILTER_OPTIONS.resolved:
      return comments.filter((c) => c.resolved && !c.deleted_at);
    case COMMENTS_FILTER_OPTIONS.yourThreadsAndMentions:
      return comments.filter((comment) => {
        const isCommentActive = !comment.deleted_at;

        return (
          isCommentActive &&
          (isCurrentUserAuthor(comment) ||
            isCurrentUserMentioned(comment) ||
            isCurrentUserMentionedOrCommentedInThread(comment))
        );
      });
    case COMMENTS_FILTER_OPTIONS.all:
    default:
      return comments.filter((c) => !c.deleted_at);
  }
}
