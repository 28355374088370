import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TOOLTIP_DELAY } from '@/storychief/constants/Constants';
import getFullName from '@/storychief/utils/getFullName';

const propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};
const defaultProps = {};

function CommentUser({ user }) {
  // Variables
  const fullName = user.firstname ? getFullName(user) : user.email;

  // Rendering
  return (
    <OverlayTrigger
      placement="top"
      delayShow={TOOLTIP_DELAY}
      overlay={<Tooltip id="tooltip">{fullName}</Tooltip>}
    >
      <strong className="comment-user">{user.firstname || user.email}</strong>
    </OverlayTrigger>
  );
}

CommentUser.propTypes = propTypes;
CommentUser.defaultProps = defaultProps;

export default CommentUser;
