import { EditorState, Modifier, SelectionState } from 'draft-js';

export default function updateEditorComment(editorComment, editorState, resolved) {
  if (!editorComment) {
    return editorState;
  }

  let newContentState = editorState.getCurrentContent();

  editorComment.ranges.forEach((range) => {
    const newSelectionState = new SelectionState({
      anchorOffset: range.start,
      anchorKey: range.blockKey,
      focusOffset: range.end,
      focusKey: range.blockKey,
    });

    if (resolved) {
      newContentState = Modifier.applyInlineStyle(
        newContentState,
        newSelectionState,
        `${editorComment.key}-RESOLVED`,
      );
    } else {
      newContentState = Modifier.removeInlineStyle(
        newContentState,
        newSelectionState,
        `${editorComment.key}-RESOLVED`,
      );
    }
  });

  return EditorState.set(editorState, { currentContent: newContentState });
}
