import { gql, useQuery } from '@apollo/client';
import usePreview from '@/storychief/hooks/usePreview';

const USERS_FOR_MENTIONS_QUERY = gql`
  query UsersForMentionsQuery {
    usersForMentions: users {
      __typename
      id
      role
      firstname
      lastname
      profile_picture
    }
  }
`;

/**
 * @see resources/assets/js/packages/graphql/init-data/global/users.js Public can only cache data, passed by a controller
 *
 * @returns {{data: {usersForMentions: import('@/users/typeDefs').User[]}, loading: boolean}}
 */
function useUsersForMentions() {
  const { preview } = usePreview();

  // On a public page, only use
  return useQuery(USERS_FOR_MENTIONS_QUERY, {
    fetchPolicy: preview ? 'cache-only' : 'cache-and-network',
  });
}

export default useUsersForMentions;
