import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@iconify-icon/react';
import CommentUser from '@/comments/components/CommentUser';
import { formatDate } from '@/date';
import getDateObject from '@/date/getDateObject';
import CommentActions from '@/comments/components/CommentActions';
import StoryChief from '@/storychief';
import {
  COMMENT_VISIBILITY_INTERNAL,
  COMMENT_VISIBILITY_PUBLIC,
} from '@/comments/constants/Constants';
import { TOOLTIP_DELAY } from '@/storychief/constants/Constants';

const propTypes = {
  comment: PropTypes.shape({
    created_at: PropTypes.string,
    user_email: PropTypes.string,
    user: PropTypes.shape({
      profile_picture: PropTypes.string,
    }),
    visibility: PropTypes.oneOf([COMMENT_VISIBILITY_INTERNAL, COMMENT_VISIBILITY_PUBLIC]),
    parent_id: PropTypes.string,
  }).isRequired,
  isEditing: PropTypes.bool,
  isProcessing: PropTypes.bool,
  isResolvable: PropTypes.bool,
  onResolve: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};
const defaultProps = {
  isEditing: false,
  isProcessing: false,
  isResolvable: false,
  onResolve: () => {},
  onDelete: () => {},
  onEdit: () => {},
};

function CommentHeader({
  comment,
  isEditing,
  isResolvable,
  isProcessing,
  onResolve,
  onDelete,
  onEdit,
}) {
  function getVisibilityIcon() {
    if (comment.visibility === COMMENT_VISIBILITY_INTERNAL) {
      return 'fa:lock';
    }

    return 'fa:globe';
  }

  function getVisibilityTooltipMessage() {
    if (comment.visibility === COMMENT_VISIBILITY_INTERNAL) {
      return 'Visible to team members only';
    }

    return 'Visible to team members and external users';
  }

  return (
    <div className="comment-header d-flex flex-align-items-center flex-gap-lg small space-1">
      <div className="comment-header__img">
        <img
          alt=""
          className="comment-profile-picture img-circle"
          src={comment.user ? comment.user.profile_picture : StoryChief.getDefaultProfilePicture()}
          role="presentation"
        />
      </div>
      <div className="d-flex flex-align-items-center flex-gap-sm flex-grow">
        <div className="comment-header__user">
          <CommentUser user={comment.user || { email: comment.user_email }} />
        </div>
        {!!comment.created_at && (
          <div className="comment-header__created-at">
            <span>
              <OverlayTrigger
                placement="top"
                delayShow={TOOLTIP_DELAY}
                overlay={
                  <Tooltip id="tooltip">{formatDate(comment.created_at, 'monthLong')}</Tooltip>
                }
              >
                <span className="text-muted small">
                  {getDateObject(comment.created_at).toRelative({ style: 'narrow' })}
                </span>
              </OverlayTrigger>
            </span>
          </div>
        )}
        {!comment.parent_id && (
          <div className="comment-header__visibility text-muted">
            <OverlayTrigger
              placement="top"
              delayShow={TOOLTIP_DELAY}
              overlay={<Tooltip id="visibility-tooltip">{getVisibilityTooltipMessage()}</Tooltip>}
            >
              <Icon icon={getVisibilityIcon()} inline width="10" height="10" className="ml-1" />
            </OverlayTrigger>
          </div>
        )}

        <div className="flex-grow" />

        <div className="comment-header__actions d-flex flex-align-items-center">
          <CommentActions
            isEditing={isEditing}
            isProcessing={isProcessing}
            isShowResolve={isResolvable}
            comment={comment}
            onResolve={onResolve}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        </div>
      </div>
    </div>
  );
}

CommentHeader.propTypes = propTypes;
CommentHeader.defaultProps = defaultProps;

export default CommentHeader;
