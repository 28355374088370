import { UPDATE_TITLE_EDITOR_STATE } from './types';
import { getTitleEditorComments } from '@/editor/selectors';
import findCurrentEditorComment from '@/editor/utils/findCurrentEditorComment';
import { selectedCommentEditorKeyVar } from '@/graphql/cache';
import scrollSidebarToSelectedComment from '@/comments/utils/scrollSidebarToSelectedComment';
import getEditorStateWithForcedSelection from '@/editor/utils/getEditorStateWithForcedSelection';

export function updateTitleEditorStateAsync(editorState) {
  return {
    type: UPDATE_TITLE_EDITOR_STATE,
    payload: editorState,
  };
}

export default function updateTitleEditorState(editorState) {
  return (dispatch, getState) => {
    const contentState = editorState.getCurrentContent();

    const prevContentState = getState().editor.titleEditorState.getCurrentContent();
    if (contentState !== prevContentState) {
      dispatch(updateTitleEditorStateAsync(editorState));
      return;
    }

    const selection = editorState.getSelection();
    const prevSelection = getState().editor.titleEditorState.getSelection();

    if (selection.isCollapsed() && selection.getFocusOffset() !== prevSelection.getFocusOffset()) {
      // cursor changed position, update selected EditorCommentEditorKey
      const currentEditorCommentKeys = editorState
        .getCurrentInlineStyle()
        .filter((value) => value.startsWith('COMMENT-'));
      if (currentEditorCommentKeys.size) {
        const editorComments = getTitleEditorComments({
          editor: { titleEditorState: editorState },
        });

        const currentEditorComment = findCurrentEditorComment({
          editorState,
          currentEditorCommentKeys,
          editorComments,
        });

        if (currentEditorComment) {
          const isCurrentEditorCommentKeyResolved = currentEditorCommentKeys.find(
            (editorCommentKey) => `${currentEditorComment.key}-RESOLVED` === editorCommentKey,
          );

          if (!isCurrentEditorCommentKeyResolved) {
            selectedCommentEditorKeyVar(currentEditorComment.key);
            scrollSidebarToSelectedComment({ key: currentEditorComment.key });
          }
        }
      } else {
        selectedCommentEditorKeyVar(null);
      }
    }

    dispatch(
      updateTitleEditorStateAsync(
        getEditorStateWithForcedSelection({
          selection,
          contentState,
          prevContentState,
          prevSelection,
          editorState,
        }),
      ),
    );
  };
}
