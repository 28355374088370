import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CommentHeader from '@/comments/components/CommentHeader';
import useComment from '@/comments/hooks/useComment';
import CommentInput from '@/comments/components/CommentInput';
import NewComment from '@/comments/components/NewComment';
import CommentCollaborators from './CommentCollaborators';
import CommentReply from './CommentReply';
import getDateObject from '@/date/getDateObject';
import CommentSourceText from '@/comments/components/CommentSourceText';

const propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string,
    editor_key: PropTypes.string,
    editor_text: PropTypes.arrayOf(PropTypes.string),
    content: PropTypes.string,
    resolved: PropTypes.bool,
    parent_id: PropTypes.string,
    created_at: PropTypes.string,
    user_email: PropTypes.string,
    visibility: PropTypes.number,
    user: PropTypes.shape(),
    replies: PropTypes.arrayOf(
      PropTypes.shape({
        created_at: PropTypes.string,
      }),
    ),
  }).isRequired,
  initialCommentsToShow: PropTypes.number,
  isThreadToggleable: PropTypes.bool,
  isResolvable: PropTypes.bool,
  isReplyable: PropTypes.bool,
  isSidebarComment: PropTypes.bool,
  isSelected: PropTypes.bool,
  onCancel: PropTypes.func,
  onResolve: PropTypes.func,
  onDelete: PropTypes.func,
};

const defaultProps = {
  initialCommentsToShow: 0,
  isResolvable: true,
  isThreadToggleable: true,
  isReplyable: true,
  isSelected: false,
  isSidebarComment: false,
  onCancel: () => {},
  onResolve: () => {},
  onDelete: () => {},
};

function Comment({
  initialCommentsToShow,
  isSelected,
  isResolvable,
  isThreadToggleable,
  isReplyable,
  comment,
  onCancel,
  onResolve,
  onDelete,
  isSidebarComment,
}) {
  const {
    updateComment,
    loadingUpdateMutation,
    resolveComment,
    loadingResolveComment,
    deleteComment,
    loadingDeleteComment,
  } = useComment({
    comment,
  });
  // States
  const [isEditing, setIsEditing] = useState(false);
  const [isThreadOpen, setIsThreadOpen] = useState(!isThreadToggleable);
  const [focusReplyInput, setFocusReplyInput] = useState(false);
  const [showAll, setShowAll] = useState(
    !initialCommentsToShow ||
      initialCommentsToShow >= comment.replies.length ||
      comment.replies.length === 0,
  );

  // Variables
  const isProcessing = loadingUpdateMutation || loadingDeleteComment || loadingResolveComment;
  const commentClass = classNames('comment comment--default', {
    'comment--selected': isSelected,
  });
  const replies = comment?.replies.map((c) => ({
    ...c,
    parent_id: comment.id,
    resolved: comment.resolved,
  }));
  const activeReplies = replies?.filter((c) => !c.deleted_at) || [];
  const repliesCount = activeReplies.length;
  const collaborators = getCollaborators();

  // Functions
  function getCollaborators() {
    const uniqueCollaborators = {};

    activeReplies.forEach((reply) => {
      const id = reply?.user?.id || reply.user_email;

      if (!uniqueCollaborators[id]) {
        uniqueCollaborators[id] = reply.user || {
          id: reply.user_email,
          firstname: reply.user_email,
          profile_picture: null,
        };
      }
    });

    return Object.values(uniqueCollaborators);
  }

  async function handleResolveComment() {
    if (!isResolvable) return;

    await resolveComment({
      variables: {
        id: comment.id,
        input: {
          resolved: !comment.resolved,
        },
      },
    });

    onResolve();
  }

  function handleOnEditComment() {
    setIsEditing(true);
  }

  async function handleOnEditCommentSubmit({ content, visibility }) {
    await updateComment({
      variables: {
        id: comment.id,
        input: {
          visibility,
          content,
        },
      },
    });

    setIsEditing(false);
  }

  function handleOnEditCommentCancel() {
    setIsEditing(false);
    onCancel();
  }

  async function handleOnDeleteComment() {
    await deleteComment({
      variables: {
        id: comment.id,
      },
    });

    onDelete();
  }

  function handleOnToggleThread() {
    setIsThreadOpen(!isThreadOpen);
  }

  function handleOnReply() {
    setFocusReplyInput(false);
    setFocusReplyInput(true);
    setIsThreadOpen(true);
  }

  function handleOnReplyCancel() {
    setFocusReplyInput(false);
    if (!activeReplies?.length) {
      setIsThreadOpen(false);
    }
  }

  function handleOnReplySubmit() {
    setFocusReplyInput(false);
  }

  // Rendering
  return (
    <div
      key={comment.id}
      className={commentClass}
      data-comment-id={comment.id}
      data-comment-key={comment.editor_key}
    >
      {showAll && (
        <CommentHeader
          isEditing={isEditing}
          isProcessing={isProcessing}
          comment={comment}
          isResolvable={isResolvable}
          onResolve={handleResolveComment}
          onDelete={handleOnDeleteComment}
          onEdit={handleOnEditComment}
        />
      )}

      <div className="comment-body">
        {isSidebarComment && comment.editor_text && (
          <CommentSourceText blocks={comment.editor_text} />
        )}

        {showAll ? (
          <div
            className={classNames('comment-text small', { 'form-group form-group-sm': isEditing })}
          >
            <CommentInput
              isAutofocus={isEditing}
              comment={comment}
              isReadOnly={!isEditing}
              isDisabled={isProcessing}
              onSubmit={handleOnEditCommentSubmit}
              onCancel={handleOnEditCommentCancel}
            />
          </div>
        ) : (
          <div className="comment-view-more-replies small space-2">
            <button
              type="button"
              className="btn-link btn-chromeless"
              onClick={() => setShowAll(true)}
            >
              {comment.replies.length - initialCommentsToShow} more replies
            </button>
          </div>
        )}

        {!comment.parent_id && !isThreadOpen && (
          <div className="d-flex flex-align-items-center flex-gap-md small">
            {!comment.resolved && (
              <div className="small">
                <button
                  type="button"
                  className="btn btn-link btn-chromeless"
                  onClick={handleOnReply}
                  aria-label="Reply"
                >
                  <strong>Reply</strong>
                </button>
              </div>
            )}

            <div className="comment-replies-toggle d-flex flex-align-items-center small">
              <div className="position-relative gutter-right-1">
                <CommentCollaborators
                  collaborators={collaborators}
                  onClick={handleOnToggleThread}
                />
              </div>
              {repliesCount > 0 && (
                <div className="">
                  <button
                    type="button"
                    className="btn btn-link btn-chromeless"
                    onClick={handleOnToggleThread}
                    aria-label="View thread"
                  >
                    <strong>
                      {repliesCount > 1 ? `${repliesCount} replies` : `${repliesCount} reply`}
                    </strong>
                    <span className="text-muted gutter-left-1">
                      {repliesCount > 1 ? 'Last reply ' : ''}
                      {getDateObject(activeReplies[activeReplies.length - 1].created_at).toRelative(
                        { style: 'narrow' },
                      )}
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
        )}

        {!!activeReplies?.length && isThreadOpen && (
          <div className="comment-replies d-flex flex-column">
            {activeReplies
              .slice(showAll ? 0 : comment.replies.length - initialCommentsToShow)
              .map((reply) => (
                <CommentReply key={reply.id} comment={reply} />
              ))}
            {isThreadToggleable && (
              <div className="small space-top-3 space-2">
                <button
                  type="button"
                  className="btn btn-link btn-chromeless"
                  onClick={() => setIsThreadOpen(false)}
                  aria-label="Close thread"
                >
                  <strong>Close thread</strong>
                </button>
              </div>
            )}
          </div>
        )}

        {isReplyable && isThreadOpen && !comment.resolved && !isEditing && (
          <NewComment
            key={`${comment.id}-reply-${focusReplyInput}`}
            variant="reply"
            parentId={comment.id}
            isAutofocus={focusReplyInput}
            onCancel={handleOnReplyCancel}
            onSubmit={handleOnReplySubmit}
          />
        )}
      </div>
    </div>
  );
}

Comment.propTypes = propTypes;
Comment.defaultProps = defaultProps;

export default Comment;
