import PropTypes from 'prop-types';
import { COMMENTS_FILTER_OPTIONS } from '@/comments/constants/Constants';
import Comment from './Comment';
import getFilteredComments from '@/comments/utils/getFilteredComments';

const propTypes = {
  comments: PropTypes.arrayOf(PropTypes.shape({})),
  filter: PropTypes.oneOf(Object.values(COMMENTS_FILTER_OPTIONS)),
  selectedCommentId: PropTypes.string,
  isCommentSelectable: PropTypes.bool,
  isCommentResolvable: PropTypes.bool,
  isCommentThreadToggleable: PropTypes.bool,
  isCommentReplyable: PropTypes.bool,
  onCommentSelected: PropTypes.func,
  onCommentCancel: PropTypes.func,
  onCommentDelete: PropTypes.func,
  onCommentResolve: PropTypes.func,
  onCommentReopen: PropTypes.func,
  initialCommentsToShow: PropTypes.number,
  variant: PropTypes.oneOf(['sidebar', 'editor']),
};
const defaultProps = {
  comments: [],
  filter: COMMENTS_FILTER_OPTIONS.open,
  selectedCommentId: null,
  isCommentSelectable: true,
  isCommentResolvable: true,
  isCommentThreadToggleable: true,
  isCommentReplyable: true,
  initialCommentsToShow: 0,
  onCommentSelected: () => {},
  onCommentCancel: () => {},
  onCommentDelete: () => {},
  onCommentResolve: () => {},
  onCommentReopen: () => {},
  variant: 'sidebar',
};

function CommentsList({
  comments,
  filter,
  selectedCommentId,
  isCommentSelectable,
  isCommentResolvable,
  isCommentThreadToggleable,
  isCommentReplyable,
  initialCommentsToShow,
  onCommentSelected,
  onCommentCancel,
  onCommentDelete,
  onCommentResolve,
  onCommentReopen,
  variant,
}) {
  // Render
  return (
    <div className={`comments comments--${variant}`}>
      {getFilteredComments(comments, filter).map((comment) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div key={comment.id} onClick={() => onCommentSelected(comment.id)}>
          <Comment
            key={comment.id}
            comment={comment}
            isSelected={isCommentSelectable ? comment.id === selectedCommentId : undefined}
            isResolvable={isCommentResolvable}
            isSelectable={isCommentSelectable}
            isThreadToggleable={isCommentThreadToggleable}
            isReplyable={isCommentReplyable}
            isSidebarComment={variant === 'sidebar'}
            onCancel={onCommentCancel}
            onDelete={onCommentDelete}
            onResolve={onCommentResolve}
            onReopen={onCommentReopen}
            initialCommentsToShow={initialCommentsToShow}
          />
        </div>
      ))}
    </div>
  );
}

CommentsList.propTypes = propTypes;
CommentsList.defaultProps = defaultProps;

export default CommentsList;
