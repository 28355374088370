import { gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import ModelContext from '@/storychief/context/ModelContext';
import usePreview from '@/storychief/hooks/usePreview';
import useModelSignKey from '@/storychief/hooks/useModelSignKey';

const INTERNAL_AVAILABLE_USERS_QUERY = gql`
  query InternalAvailableUsersForContentQuery($input: AvailableUsersForContentInput!) {
    availableUsersForContent(input: $input) {
      __typename
      id
      role
      firstname
      lastname
      profile_picture
    }
  }
`;

const EXTERNAL_AVAILABLE_USERS_QUERY = gql`
  query ExternalAvailableUsersForContentQuery($input: AvailableUsersForContentInput!) {
    availableUsersForContent: externalAvailableUsersForContent(input: $input) {
      __typename
      id
      role
      firstname
      lastname
      profile_picture
    }
  }
`;

function useAvailableUsersForContent() {
  const { modelId, modelType } = useContext(ModelContext);

  const { preview } = usePreview();
  const modelSignKey = useModelSignKey({
    typename: modelType,
    id: modelId,
  });

  const { data: { availableUsersForContent } = [] } = useQuery(
    preview ? EXTERNAL_AVAILABLE_USERS_QUERY : INTERNAL_AVAILABLE_USERS_QUERY,
    {
      variables: {
        input: {
          model_type: modelType,
          model_id: modelId,
        },
      },
      skip: !modelId || !modelType,
      context: preview
        ? {
            credentials: 'omit',
            headers: modelSignKey,
          }
        : {},
    },
  );

  return availableUsersForContent || [];
}

export default useAvailableUsersForContent;
