import { EditorState, Modifier, SelectionState } from 'draft-js';

export default function deleteEditorComment(editorComment, editorState) {
  let newContentState = editorState.getCurrentContent();

  editorComment.ranges.forEach((range) => {
    const newSelectionState = new SelectionState({
      anchorOffset: range.start,
      anchorKey: range.blockKey,
      focusOffset: range.end,
      focusKey: range.blockKey,
    });
    // remove comment style
    newContentState = Modifier.removeInlineStyle(
      newContentState,
      newSelectionState,
      editorComment.key,
    );
    // also remove resolved comment style
    newContentState = Modifier.removeInlineStyle(
      newContentState,
      newSelectionState,
      `${editorComment.key}-RESOLVED`,
    );
  });

  return EditorState.set(editorState, { currentContent: newContentState });
}
