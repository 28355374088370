import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as Popover from '@radix-ui/react-popover';
import { MenuItem, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@iconify-icon/react';
import StoryChief from '@/storychief';
import confirm from '@/storychief/utils/confirm';
import { TOOLTIP_DELAY } from '@/storychief/constants/Constants';

const propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    resolved: PropTypes.bool.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    parent_id: PropTypes.string,
    replies: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  isEditing: PropTypes.bool,
  isShowResolve: PropTypes.bool,
  isProcessing: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onResolve: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
const defaultProps = {
  isEditing: false,
  isProcessing: false,
  isShowResolve: true,
};

function CommentActions({
  comment,
  isEditing,
  isProcessing,
  isShowResolve,
  onEdit,
  onResolve,
  onDelete,
}) {
  const isCurrentUserGuest = !StoryChief?.user?.id;
  const isOwner = comment?.user?.id && comment.user.id === `${StoryChief?.user?.id}`;
  const canEdit = !comment.resolved && isOwner;
  const canDelete = isOwner;
  const isShowMenu = canEdit || canDelete;

  async function handleOnDelete() {
    const isConfirmed = await confirm({
      omitInput: true,
      confirmCTA: 'Delete comment',
      title: 'Delete comment?',
      description:
        comment?.replies?.length > 0
          ? 'Are you sure you want to delete this comment and all of its replies?'
          : 'Are you sure you want to delete this comment?',
    });

    if (isConfirmed) {
      onDelete();
    }
  }

  if (isCurrentUserGuest || isEditing) {
    return null;
  }

  return (
    <>
      {isShowResolve && !comment.parent_id && (
        <OverlayTrigger
          placement="top"
          delayShow={TOOLTIP_DELAY}
          overlay={
            <Tooltip id="resolve-tooltip">{comment.resolved ? 'Re-open' : 'Resolve'}</Tooltip>
          }
        >
          <button
            type="button"
            className={classNames('btn btn-chromeless', { 'btn-primary': comment.resolved })}
            onClick={onResolve}
            aria-label={comment.resolved ? 'Re-open' : 'Resolve'}
            disabled={isProcessing}
          >
            <Icon icon="custom:check-circled-o" />
          </button>
        </OverlayTrigger>
      )}
      {isShowMenu && (
        <Popover.Root>
          <Popover.Trigger
            className="btn-chromeless dropdown-toggle btn btn-sm"
            disabled={isProcessing}
            aria-label="Toggle actions"
          >
            <Icon icon="bi:three-dots" height="16" rotate="90deg" />
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content className="dropdown-menu" align="end">
              {canEdit && (
                <MenuItem key="edit" onSelect={onEdit}>
                  <Icon icon="fa:pencil" className="mr-1 text-xs" /> Edit
                </MenuItem>
              )}
              {canDelete && (
                <MenuItem key="delete" onSelect={handleOnDelete}>
                  <Icon icon="fa:trash-o" className="mr-1 text-xs" /> Delete
                </MenuItem>
              )}
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      )}
    </>
  );
}

CommentActions.propTypes = propTypes;
CommentActions.defaultProps = defaultProps;

export default CommentActions;
