import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CommentHeader from '@/comments/components/CommentHeader';
import useComment from '@/comments/hooks/useComment';
import CommentInput from '@/comments/components/CommentInput';

const propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string,
    editor_key: PropTypes.string,
    content: PropTypes.string,
    resolved: PropTypes.bool,
    parent_id: PropTypes.string,
    created_at: PropTypes.string,
    user_email: PropTypes.string,
    visibility: PropTypes.number,
    user: PropTypes.shape(),
    replies: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  onCommentDelete: PropTypes.func,
};

const defaultProps = {
  onCommentDelete: () => {},
};

function CommentReply({ comment, onCommentDelete }) {
  const { updateComment, loadingUpdateMutation, deleteComment, loadingDeleteComment } = useComment({
    comment,
    onCommentDelete,
  });

  // States
  const [isEditing, setIsEditing] = useState(false);

  // Variables
  const isProcessing = loadingUpdateMutation || loadingDeleteComment;
  const commentClass = classNames('comment comment--reply');

  // Functions
  function handleUpdateComment({ visibility, content }) {
    setIsEditing(false);
    updateComment({
      variables: {
        id: comment.id,
        input: {
          content,
          visibility,
          parent_id: comment.parent_id,
        },
      },
    });
  }

  function handleOnEditComment() {
    setIsEditing(true);
  }

  function handleOnEditCommentCancel() {
    setIsEditing(false);
  }

  function handleOnDeleteComment() {
    deleteComment({
      variables: {
        id: comment.id,
      },
    });
  }

  // Rendering
  return (
    <div key={comment.id} className={commentClass} data-comment-key={comment.editor_key}>
      <CommentHeader
        isEditing={isEditing}
        isProcessing={isProcessing}
        comment={comment}
        onDelete={handleOnDeleteComment}
        onEdit={handleOnEditComment}
      />

      <div className="comment-body">
        <div
          data-testid="comment-reply"
          className={classNames('comment-text small', { 'form-group form-group-sm': isEditing })}
        >
          <CommentInput
            isAutofocus
            variant="reply"
            comment={comment}
            isReadOnly={!isEditing}
            isDisabled={isProcessing}
            isShowVisibilitySelect={false}
            onSubmit={handleUpdateComment}
            onCancel={handleOnEditCommentCancel}
          />
        </div>
      </div>
    </div>
  );
}

CommentReply.propTypes = propTypes;
CommentReply.defaultProps = defaultProps;

export default CommentReply;
