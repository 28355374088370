import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import StoryChief from '@/storychief';
import { TOOLTIP_DELAY } from '@/storychief/constants/Constants';
import getFullName from '@/storychief/utils/getFullName';

const propTypes = {
  collaborators: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string,
      profile_picture: PropTypes.string,
    }),
  ),
  onClick: PropTypes.func.isRequired,
};
const defaultProps = {
  collaborators: [],
};

function CommentCollaborators({ collaborators, onClick }) {
  // Variables
  const AVATAR_SIZE = 18;
  const MAX_COLLABORATORS = 5;
  const moreCount = Math.max(collaborators.length - MAX_COLLABORATORS, 0);

  // Render
  if (collaborators.length === 0) {
    return null;
  }

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="comment-thread-collaborator-tooltip">
          {collaborators.map((user) => (
            <div key={user.id}>{getFullName(user)}</div>
          ))}
        </Tooltip>
      }
      delayShow={TOOLTIP_DELAY}
    >
      <button
        className="btn btn-chromeless list-profile-pictures d-inline-block after-clickable"
        type="button"
        onClick={onClick}
      >
        {collaborators.slice(0, MAX_COLLABORATORS).map((user) => (
          <img
            key={user.id}
            src={user.profile_picture || StoryChief.asset('images/placeholders/profile.png')}
            width={AVATAR_SIZE}
            height={AVATAR_SIZE}
            className="d-block img-responsive img-circle"
            alt={getFullName(user)}
          />
        ))}

        {moreCount > 0 && (
          <div
            className="list-profile-pictures-more"
            style={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}
          >
            +{moreCount}
          </div>
        )}
      </button>
    </OverlayTrigger>
  );
}

CommentCollaborators.propTypes = propTypes;
CommentCollaborators.defaultProps = defaultProps;

export default CommentCollaborators;
