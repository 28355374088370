import { useState } from 'react';
import PropTypes from 'prop-types';
import StoryChief from '@/storychief';
import CommentInput from '@/comments/components/CommentInput';
import useComments from '@/comments/hooks/useComments';

const propTypes = {
  variant: PropTypes.oneOf(['default', 'reply', 'editor', 'editor-reply']),
  isAutofocus: PropTypes.bool,
  isAnimated: PropTypes.bool,
  isResettable: PropTypes.bool,
  editorKey: PropTypes.string,
  editorText: PropTypes.arrayOf(PropTypes.string),
  editorRanges: PropTypes.arrayOf(PropTypes.shape({})),
  parentId: PropTypes.string,
  disableVisibilitySelect: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onFocus: PropTypes.func,
};
const defaultProps = {
  variant: 'default',
  isAutofocus: false,
  isAnimated: false,
  isResettable: false,
  disableVisibilitySelect: false,
  editorKey: null,
  editorRanges: null,
  editorText: null,
  parentId: null,
  onSubmit: () => {},
  onCancel: () => {},
  onFocus: () => {},
};

function NewComment({
  variant,
  isAutofocus,
  isAnimated,
  isResettable,
  disableVisibilitySelect,
  editorKey,
  editorRanges,
  editorText,
  parentId,
  onSubmit,
  onCancel,
  onFocus,
}) {
  // States
  const [editorId, setEditorId] = useState(0);

  // Hooks
  const { addComment, loadingAddComment } = useComments();

  async function handleOnSubmit({ content, visibility }) {
    const response = await addComment({
      content,
      visibility,
      editorKey,
      editorRanges,
      parentId,
      editorText,
    });

    if (response) {
      resetEditor();
    }

    onSubmit();
  }

  function resetEditor() {
    if (isResettable) {
      setEditorId((currentValue) => currentValue + 1);
    }
  }

  function handleOnCancel() {
    onCancel({ key: editorKey, ranges: editorRanges });
    resetEditor();
  }

  function handleOnFocus() {
    onFocus({ key: editorKey, ranges: editorRanges });
  }

  // Rendering
  return (
    <form className={`comment-new comment-new--${variant} d-flex`}>
      <div className="comment-new__aside">
        <img
          className="comment-profile-picture img-circle"
          src={StoryChief.getProfilePicture()}
          alt=""
          role="presentation"
        />
      </div>
      <div className="comment-new__body">
        <CommentInput
          key={editorId}
          variant={variant}
          isAutofocus={isAutofocus}
          isDisabled={loadingAddComment}
          isAnimated={isAnimated}
          isShowVisibilitySelect={!parentId && !disableVisibilitySelect}
          editorKey={editorKey}
          onSubmit={handleOnSubmit}
          onFocus={handleOnFocus}
          onCancel={handleOnCancel}
        />
      </div>
    </form>
  );
}

NewComment.propTypes = propTypes;
NewComment.defaultProps = defaultProps;

export default NewComment;
